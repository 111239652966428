<template>
  <travio-center-container grid-width="1/2" pageTitle="Add New Application">
    <vx-card>
      <div class="flex flex-wrap items-center mb-base">
        <vs-avatar :src="form.logoUrl" icon="cloud_upload" @click="$refs.companyLogoInput.click()" size="70px" class="mr-4 mb-4 company-logo-avatar" />
        <div>
          <input type="file" hidden id="companyLogoInput" ref="companyLogoInput" v-on:change="handleFileUpload"/>
          <vs-button @click="$refs.companyLogoInput.click()" class="mr-4 sm:mb-0 mb-2">Upload App Logo</vs-button>
          <vs-button @click="removeLogo" type="border" color="danger">Remove</vs-button>
          <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
        </div>
      </div>

      <div class="vx-row">
        <!-- General Info Col -->
        <div class="vx-col w-full md:w-1/2">

          <!-- Col Header -->
          <div class="flex items-end">
            <feather-icon icon="SettingsIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">General</span>
          </div>

          <!-- Col Content -->
          <div>
            <vs-input class="w-full mt-4" label="Application Name*" v-model="form.applicationName" v-validate="'max:50'" name="applicationName" />
            <span class="text-danger text-sm">{{ errors.first('applicationName') }}</span>

            <div class="mt-4">
              <label class="text-sm">Web Platform</label>
              <v-select :value="selectedWebPlatform" @input="setSelectedWebPlatform" :options="webPlatformOptions" />
            </div>
            <div class="mt-4 mb-8">
              <label class="text-sm">Subscription*</label>
              <v-select :value="selectedSubscription" @input="setSelectedSubscription" :options="subscriptionOptions" />
            </div>
            

          </div>
          <!-- Col Header -->
          <div class="flex items-end">
            <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Location</span>
          </div>
          <!-- Col Content -->
          <div>
            <div class="mt-4">
              <label class="text-sm">Region*</label>
              <v-select :value="selectedRegion" @input="setSelectedRegion" :options="regionOptions" />
            </div>
            <vs-input class="w-full mt-4" label="Address 1" v-model="form.address1" v-validate="'max:250'" name="address1" />
            <span class="text-danger text-sm">{{ errors.first('address1') }}</span>
            <vs-input class="w-full mt-4" label="Address 2" v-model="form.address2" v-validate="'max:250'" name="address2" />
            <span class="text-danger text-sm">{{ errors.first('address2') }}</span>

            <div class="vx-row">
              <div class="vx-col w-full md:w-1/2">
                <vs-input class="w-full mt-4" label="City" v-model="form.city" v-validate="'max:50'" name="city" />
                <span class="text-danger text-sm">{{ errors.first('city') }}</span>
              </div>
              <div class="vx-col w-full md:w-1/2">
                <vs-input class="w-full mt-4" label="State" v-model="form.state" v-validate="'max:50'" name="state" />
                <span class="text-danger text-sm">{{ errors.first('state') }}</span>
              </div>
            </div>

            <!-- Country -->
            <div class="mt-4">
              <label class="text-sm">Country</label>
              <v-select :value="selectedCountry" @input="setSelectedCountry" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            </div>
          </div>

        </div>

        <!-- Booking Col -->
        <div class="vx-col w-full md:w-1/2">

          <!-- Col Header -->
          <div class="flex items-end md:mt-0 mt-base">
            <feather-icon icon="CalendarIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Booking</span>
          </div>

          <!-- Col Content -->
          <div>
            <vs-input class="w-full mt-4" label="Booking Email Name" v-model="form.bookingEmailName" v-validate="'max:50'" name="bookingEmailName" />
            <span class="text-danger text-sm">{{ errors.first('bookingEmailName') }}</span>

            <vs-input class="w-full mt-4" label="Booking Email" v-model="form.bookingEmail" v-validate="'max:100|email'" name="bookingEmail" />
            <span class="text-danger text-sm">{{ errors.first('bookingEmail') }}</span>

            <vs-input class="w-full mt-4" label="Booking Telephone Name" v-model="form.bookingTelephoneName" v-validate="'max:150'" name="bookingTelephoneName" />
            <span class="text-danger text-sm">{{ errors.first('bookingTelephoneName') }}</span>

            <vs-input class="w-full mt-4 mb-8" label="Booking Telephone" v-model="form.bookingTelephone" v-validate="'max:20'" name="bookingTelephone" />
            <span class="text-danger text-sm">{{ errors.first('bookingTelephone') }}</span>

          </div>

          <!-- Col Header -->
          <div class="flex items-end md:mt-0 mt-base">
            <feather-icon icon="PhoneIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Suport</span>
          </div>

          <!-- Col Content -->
          <div>
            <vs-input class="w-full mt-4" label="Support Email Name" v-model="form.supportEmailName" v-validate="'max:50'" name="supportEmailName" />
            <span class="text-danger text-sm">{{ errors.first('supportEmailName') }}</span>

            <vs-input class="w-full mt-4" label="Support Email" v-model="form.supportEmail" v-validate="'max:100|email'" name="supportEmail" />
            <span class="text-danger text-sm">{{ errors.first('supportEmail') }}</span>

            
            <vs-input class="w-full mt-4" label="Support Telephone Name" v-model="form.supportTelephoneName" v-validate="'max:150'" name="supportTelephoneName" />
            <span class="text-danger text-sm">{{ errors.first('supportTelephoneName') }}</span>

            <vs-input class="w-full mt-4" label="Support Telephone" v-model="form.supportTelephone" v-validate="'max:20'" name="supportTelephone" />
            <span class="text-danger text-sm">{{ errors.first('supportTelephone') }}</span>
          </div>
        </div>
      </div>
      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>
      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button @click="handleSubmit" class="mt-4">Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  props: { 
    companyId: { required: true }
  },
  data () {
    return {
      form: new Form({
        applicationName: '',
        regionId: 0,
        subscriptionId: 0,
        webPlatformId: null,
        logoUrl: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        countryCode: '',
        bookingEmailName: '',
        bookingEmail: '',
        bookingTelephoneName: '',
        bookingTelephone: '',
        supportEmailName: '',
        supportEmail: '',
        supportTelephoneName: '',
        supportTelephone: '',
        logoFile: null
      }),
      selectedCountry: null,
      selectedRegion: null,
      selectedSubscription: null,
      selectedWebPlatform: null,
      subscriptionOptions: [],
      regionOptions: [],
      webPlatformOptions: [],
    }
  },
  computed: {
    countryOptions () {
      return this.$store.state.lookups.countryOptions
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  created () {
    this.$http.get(`api/companies/${this.activeUserInfo.companyId}/applications/lookups`)
      .then(response => {
        this.regionOptions = response.data.regionOptions
        this.subscriptionOptions = response.data.subscriptionOptions
        this.webPlatformOptions = response.data.webPlatformOptions
      })
      .catch(err => console.error(err) )
  },
  mounted () {
  },
  beforeDestroy () {
    this.handleReset()
  },
  methods: {
    setSelectedRegion (value) {
      this.form.regionId = value ? value.code : null
      this.selectedRegion = value;
    },
    setSelectedCountry (value) {
      this.form.countryCode = value ? value.code : null
      this.selectedCountry = value;
    },
    setSelectedSubscription (value) {
      this.form.subscriptionId = value ? value.code : null
      this.selectedSubscription = value;
    },
    setSelectedWebPlatform (value) {
      this.form.webPlatformId = value ? value.code : null
      this.selectedWebPlatform = value;
    },
    handleFileUpload () {
      this.form.logoFile = this.$refs.companyLogoInput.files[0];
      // Temporary render file, can't upload yet since Application ID is not yet available
      if (this.form.logoFile) {
        this.form.logoUrl = URL.createObjectURL(this.form.logoFile);
      }
    },
    submitLogoIcon (applicationId) {
      let formData = new FormData();
      formData.append('logoFile', this.form.logoFile)
      return new Promise((resolve, reject) => {
        this.$http.post(`api/companies/${this.$route.params.companyId}/applications/${applicationId}/logos`, formData, {
            headers: { 'Content-Type': 'multipart/form-data'}
        })
        .then(response => {
          this.form.logoUrl = response.data.logoUrl
          resolve(response)
        })
        .catch(error => reject(error))
      })
    },
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          let formData = new FormData();
          const postData = this.form.data()
          let props = Object.keys(postData)
          props.forEach(key => {
            //skip nulls since it will be parsed as "null"
            postData[key] && formData.append(key, postData[key]);  
          })
          
          this.$vs.loading()
          this.$http.post( `api/admin/companies/${this.companyId}/apps`, formData, {
            headers: { 'Content-Type': 'multipart/form-data'}
          })
          .then(response => {
            this.$_notifySuccess('Company successfully updated.')
            this.$router.push({ name: 'admin-crm-company-edit', params: { companyId: this.companyId, presetTabIndex: 2 } })
          })
          .catch(error => this.$_notifyFailureByResponseData(error.response.data))
          .finally(() => this.$vs.loading.close())
        }
      })
    },
    handleReset () {
      this.selectedCountry = null
      this.form = new Form(this.form.originalData)
    },
    handleCancel () {
      this.$router.push({ name: 'admin-crm-company-edit', params: { companyId: this.companyId, presetTabIndex: 2 } })
    },
    removeLogo () {
      this.$refs.companyLogoInput.value = '';
      this.form.logoUrl = '';
    }
  }
}
</script>

<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }

  /* Fix date picker issue month selection partially hidden*/
  .flatpickr-months .flatpickr-month {
    overflow: initial;
  }
</style>